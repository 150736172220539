<template>
    <r-e-drawer class="drawer-tenant-info" title="租户详情" :visible.sync="drawerVisible" :size="1700" modal
                wrapper-closable drawer-wrapper>
        <div class="drawer-container">
            <div class="container-content" style="display: flex">
                <div style="width: 30%;height: 100%;padding: 15px;border: 1px solid #cfcfcf;border-right: 0">
                    <div class="drawer-title" style="font-size: 24px;margin-bottom: 15px;">
                        {{ tenantData.name }}
                    </div>

                    <div style="padding: 15px;border: 1px solid #d6e9c6;border-radius: 2px;margin-bottom: 10px;display: flex;justify-content: space-between">
                        <div>
                            <div style="font-size: 18px;color: #36a9e0;">0</div>
                            <div>欠费</div>
                        </div>
                        <div>
                            <div style="font-size: 18px;color: #36a9e0;">0</div>
                            <div>维修</div>
                        </div>
                        <div>
                            <div style="font-size: 18px;color: #36a9e0;">0</div>
                            <div>提醒</div>
                        </div>
                    </div>
                </div>
                <div style="width: 70%;height: 100%;border: 1px solid #cfcfcf;">
                    <div style="display: flex;height: 50px;background-color: rgb(241, 241, 241)">
                        <div style="padding: 15px;" v-for="item in tenantInfoTabOptions" :key="item.name"
                             :style="{backgroundColor:item.name === activeTab?'#fff':''}" @click="activeTab = item.name"
                        >
                            {{item.label}}
                        </div>
                    </div>
                    <div style="height: 90%;">
                        <div class="container-content hidden-scrollbar" style="padding: 15px;margin-top: 0">
                            <component :is="activeTab" :tenantData="tenantData"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </r-e-drawer>
</template>

<script>
    export default {
        name: "drawer-tenant-info",
        data() {
            return {
                drawerVisible: false,
                tenantData: {},
                activeTab: 'baseInfo',
                tenantInfoTabOptions: [
                    {label: "承租人详情", name: "baseInfo"},
                    {label: "房租", name: "bill"},
                    {label: "其他费用", name: "other"},
                    {label: "催款记录", name: "call"},
                    {label: "账单记录", name: "retur"}
                ],
                expenseBalance: 0,
                rentalBalance: 0,
                depoBalance: 0,
                formSearch: {},
            }
        },
        components: {
            baseInfo: () => import("./part/baseInfo"),
            bill: () => import("./part/bill"),
            other: () => import("./part/other"),
            call: () => import("./part/call"),
            retur: () => import("./part/retur")
        },
        methods: {
            openDrawer(row) {
                this.tenantData = row;
                this.drawerVisible = true;
            },
            closeDrawer() {
                this.$emit('handleSearch');
                this.drawerVisible = false;
            },
        },
        watch: {
            drawerVisible(value) {
                if (!value) this.closeDrawer();
            }
        }
    }
</script>

<style lang="scss" scoped>
    .drawer-tenant-info {
        .drawer-header {
            .room-status {
                color: #1F7CF6;
                font-size: rem(14px);
                border-radius: 15px;
                padding: VH(3px) VW(10px);
                background: #CEE7FF;
                margin-left: VW(10px);
            }
        }

        .drawer-container {
            margin-left: VW(5px);

            .container-header {
                width: fit-content;
                border-radius: 4px;
                box-shadow: 0 6px 12px rgba(0, 45, 170, 0.16);

                .item {
                    padding: VH(15px) VW(30px);
                    color: #666;
                    font-size: rem(16px);
                    cursor: pointer;
                    border-radius: 4px;
                    margin-right: VW(5px);

                    &:last-child {
                        margin-right: 0;
                    }

                    &.is-active-tab,
                    &:hover {
                        color: #fff;
                        background: linear-gradient(80deg, #A8D4FF 0%, #2386F6 100%);
                        box-shadow: 0 6px 12px rgba(0, 45, 170, 0.16);
                    }
                }
            }

            .container-content {
                height: VH(870px);
                margin-top: VH(20px);
            }
        }

        /deep/ .container-content {
            .table-title {
                position: relative;
                padding-left: 10px;
                margin: 20px 0;

                &::before {
                    width: 5px;
                    height: 100%;
                    content: '';
                    background-color: #F5A623;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
        }
    }
</style>
